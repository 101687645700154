<template>
  <div id="report">
    <main>
      <router-view />
    </main>
  </div>
</template>

<script>
export default {
  name: "Report",
  mounted() {
    this.$emit("mounted");
  }
};
</script>

<style lang="scss">
@import "~@sass/bulma/custom-variables";
html {
  overflow-y: scroll !important;
}
</style>
